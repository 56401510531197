<template>
	<CCardBody>
		<div v-show="loadingTable" class="text-center">
			<ellipsis-loader :color="'#54f1d2'" />
		</div>
		<div v-show="!loadingTable">
			<CRow>
				<CCol md="4">
					<CInput
						label="No HP"
						type="text"
						v-model="frm.hp"
						placeholder="081234567890, 6281234567890"
						@keypress="getPembeli"
						@change="getPembeli"
					/>
				</CCol>
				<div class="col-md-4">
					<div role="group" class="form-group">
						<label for="pembeli" class=""> Pembeli </label><br />
						<label
							:style="customer.buyerId ? 'cursor: pointer' : ''"
							:class="customer.buyerId ? 'text-info mt-2' : 'mt-2'"
							id="pembeli"
							@click="setPengirim"
							v-html="customer.nama + ' - ' + customer.alamat"
						/>
					</div>
				</div>
			</CRow>
			<CRow>
				<CCol md="4">
					<CInput
						label="Nama"
						type="text"
						v-model="frm.nama"
						placeholder="nama"
					/>
				</CCol>
				<CCol md="8">
					<CInput
						label="Alamat"
						type="text"
						v-model="frm.alamat"
						placeholder="alamat"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="3">
					<div role="group" class="form-group">
						<label for="asal" class=""> Asal </label>
						<select v-model="frm.asal" id="asal" class="form-control">
							<option :key="a" v-for="a in asal" :data-key="a" :value="a">
								{{ a }}
							</option>
						</select>
					</div>
				</CCol>
				<CCol md="3">
					<div role="group" class="form-group">
						<label for="kurier" class=""> Kurier </label>
						<select v-model="frm.kurier" id="kurier" class="form-control">
							<option :key="a" v-for="a in kurier" :data-key="a" :value="a">
								{{ a }}
							</option>
						</select>
					</div>
				</CCol>
				<CCol md="3">
					<CInput
						label="Ongkir"
						type="number"
						v-model.number="frm.ongkir"
						placeholder="ongkir"
					/>
				</CCol>
				<CCol md="3">
					<CInput
						label="Resi / Kode Marketplace"
						type="text"
						v-model="frm.resi"
						placeholder="resi / kode marketplace"
					/>
				</CCol>
			</CRow>

			<CRow>
				<CCol md="12">
					<CInput
						label="Catatan"
						type="text"
						v-model="frm.catatan"
						placeholder="catatan"
					/>
				</CCol>
			</CRow>
		</div>
	</CCardBody>
</template>

<style lang="css" scoped>
.my-custom-scrollbar {
	position: relative;
	height: 400px;
	overflow: auto;
}
.table-wrapper-scroll-y {
	display: block;
}
.select-row {
	cursor: pointer;
}
</style>

<script>
import { mapMutations, mapState } from "vuex";
import { size, debounce } from "@/plugins/utils";
export default {
	data() {
		return {
			frm: {
				catatan: "",
				hp: "",
				nama: "",
				alamat: "",
				ongkir: 0,
				resi: "",
				kurier: "",
				asal: "shopee",
				buyerId: 0,
			},
			asal: [
				"shopee",
				"tokopedia",
				"lazada",
				"jdid",
				"instagram",
				"whatsapp",
				"cod",
			],
			kurier: [
				"jne",
				"jnt",
				"sicepat",
				"antaraja",
				"tiki",
				"ninja",
				"wahana",
				"pos",
				"ojek",
				"cod",
				"lazada",
				"shopee",
			],
			customer: { nama: "belum ada", hp: "", alamat: "", buyerId: 0 },
		};
	},
	mounted() {
		if (this.info.buyerId) {
			this.frm = this.info;
			this.customer = this.frm;
		}
	},
	computed: {
		...mapState("order", ["info"]),
	},
	watch: {
		frm: {
			deep: true,
			handler(val) {
				//localStorage.setItem("po_product", JSON.stringify(val));
				this.SET_INFO(val);
			},
		},
	},
	methods: {
		...mapMutations("order", ["SET_INFO"]),
		getPembeli: debounce(async function () {
			if (this.frm.hp.length > 10) {
				const { data } = await this.$http.get("customer", {
					params: {
						sort: "hp",
						order: "asc",
						page: 1,
						per_page: 1,
						nohp: this.frm.hp,
					},
				});
				//console.log(data.data);
				this.customer = { nama: "baru", hp: "", alamat: "", buyerId: 0 };
				if (data.data.data.length > 0) {
					const d = data.data.data[0];
					this.customer = {
						nama: d.nama,
						alamat: d.alamat,
						hp: d.hp,
						buyerId: d.id,
					};
				}
			} else if (this.frm.hp.length > 1) {
				this.customer = { nama: "belum ada", hp: "", alamat: "", buyerId: 0 };
				this.customer.nama = "Loading ...";
			}
		}, 700),

		setPengirim() {
			//this.frm.buyerId = 0;
			if (
				this.customer.nama != "belum ada" &&
				this.customer.nama != "baru" &&
				this.customer.nama != "Loading ..."
			) {
				this.frm.nama = this.customer.nama;
				this.frm.alamat = this.customer.alamat;
				this.frm.hp = this.customer.hp;
				this.frm.buyerId = this.customer.buyerId;
			}
		},
	},
};
</script>

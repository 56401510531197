<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Order Form </CCardHeader>
					<Step1 v-if="next == 0" />
					<Step2 v-if="next == 1" />
					<Step3 v-if="next == 2" />
					<CCardBody style="padding-top: 0px">
						<CRow v-if="next == 2">
							<CCol md="12" class="form-inline mb-3">
								<div role="group" class="form-check form-check-inline">
									<input
										id="uid-791bkyrkb8l"
										type="checkbox"
										class="form-check-input"
										v-model="confirm"
									/>
									<label for="uid-791bkyrkb8l" class="form-check-label">
										Saya sudah meneliti kembali order yang masuk, karena saya
										sadar order yang masuk tidak bisa diedit
									</label>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol>
								<CButton
									v-show="this.next > 0"
									@click="nextto('b')"
									color="secondary"
									block
								>
									BACK
								</CButton>
							</CCol>
							<CCol>
								<CButton
									v-if="this.next < 2 && info.nama != ''"
									@click="nextto('n')"
									color="primary"
									block
								>
									NEXT ({{ this.next + 1 }}/3)
								</CButton>
								<CButton
									v-else-if="this.next == 2"
									:disabled="!confirm || loading"
									@click="save"
									color="success"
									block
								>
									SAVE ORDER
								</CButton>
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import { mapState, mapMutations } from "vuex";
import { debounce } from "@/plugins/utils";
export default {
	data() {
		return {
			next: 0,
			confirm: false,
			loading: false,
		};
	},
	components: {
		Step1,
		Step2,
		Step3,
	},
	mounted() {
		this.confirm = false;
		//console.log(this.info);
	},
	computed: {
		...mapState("order", ["product", "info"]),
	},
	methods: {
		...mapMutations("order", ["SET_PRODUCT", "SET_INFO"]),
		save: debounce(async function () {
			this.loading = true;
			try {
				let datar = {};
				const frm = {
					...this.info,
					items: this.product,
				};

				const { data } = await this.$http.post("order", frm);
				datar = data;
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.SET_PRODUCT([]);
					this.SET_INFO({});
					// this.$router.push({ name: "Dashboard" });
					this.next = 0;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),

		nextto(to) {
			if (to == "n") {
				this.next >= 3 ? (this.next = 3) : this.next++;
			} else {
				if (this.next == 3) {
					this.next = 0;
				} else this.next <= 0 ? (this.next = 0) : this.next--;
			}
		},
	},
};
</script>

<template>
	<CCardBody>
		<CRow>
			<CCol md="12" class="table-responsive">
				<table class="table table-sm">
					<thead>
						<tr>
							<td colspan="3">Nama : {{ info.nama.toUpperCase() }}</td>
							<td colspan="4">Asal : {{ info.asal.toUpperCase() }}</td>
						</tr>
						<tr>
							<td colspan="3">No HP : {{ info.hp }}</td>
							<td colspan="4">Kurier : {{ info.kurier.toUpperCase() }}</td>
						</tr>
						<tr>
							<td colspan="3">Alamat : {{ info.alamat }}</td>
							<td colspan="4">Resi : {{ info.resi.toUpperCase() }}</td>
						</tr>

						<tr>
							<th>Kode</th>
							<th>Product</th>
							<th>Ukuran</th>
							<th class="text-right">Harga</th>
							<th class="text-right">Diskon</th>
							<th class="text-right">Jumlah</th>
							<th class="text-right">Total</th>
						</tr>
					</thead>
					<tbody>
						<tr :key="i" v-for="(p, i) in productSelected">
							<td>{{ p.kode }}</td>
							<td>{{ p.product.nama }}</td>
							<td>{{ size[p.size].nama + " / " + size[p.size].alias }}</td>
							<td class="text-right">{{ p.hargaJual.toLocaleString() }}</td>
							<td class="text-right">
								{{ p.diskon.toLocaleString() }}
							</td>
							<td class="text-right">{{ p.jmlBeli }}</td>
							<td class="text-right">{{ p.total.toLocaleString() }}</td>
						</tr>
						<tr>
							<th class="text-right" colspan="5">TOTAL</th>
							<th class="text-right">{{ total()["jml"] }}</th>
							<th class="text-right">
								{{ total()["uang"].toLocaleString() }}
							</th>
						</tr>
						<tr>
							<th class="text-right" colspan="5">ONGKOS KIRIM</th>
							<th></th>
							<th class="text-right">
								{{ parseInt(info.ongkir).toLocaleString() }}
							</th>
						</tr>
						<tr>
							<th class="text-right" colspan="5">TOTAL BAYAR</th>
							<th></th>
							<th class="text-right">
								{{
									(
										parseInt(total()["uang"]) + parseInt(info.ongkir)
									).toLocaleString()
								}}
							</th>
						</tr>
					</tbody>
				</table>
				<span class="font-italic">catatan : {{ info.catatan }}</span>
			</CCol>
		</CRow>
	</CCardBody>
</template>

<style lang="css">
.col-form-label-sm {
	padding-bottom: 0px;
}
label {
	margin-bottom: 0px;
}
</style>

<script>
import { mapMutations, mapState } from "vuex";
import { size } from "@/plugins/utils";
export default {
	data() {
		return {
			productSelected: [],
			size,
		};
	},
	computed: {
		...mapState("order", ["product", "info"]),
	},
	watch: {
		productSelected: {
			deep: true,
			handler(val) {
				this.SET_PRODUCT(val);
			},
		},
	},
	mounted() {
		this.productSelected = this.product;
	},
	methods: {
		...mapMutations("order", ["SET_PRODUCT"]),

		total() {
			let jml = 0;
			let uang = 0;
			for (let i = 0; i < this.productSelected.length; i++) {
				const el = this.productSelected[i];
				jml += el.jmlBeli;
				uang += el.total;
			}
			return { jml, uang };
		},
	},
};
</script>

<template>
	<div>
		<CCardBody>
			<CRow>
				<div class="col-md-2 mb-2">
					<button
						:disabled="products.length < 1"
						@click="modal = true"
						class="btn btn-sm btn-secondary"
					>
						SCAN
					</button>
				</div>
				<CCol class="" md="4">
					<CInput
						size="sm"
						label=""
						placeholder="Kode Barang"
						v-model="filter"
						ref="filter"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol class="table-responsive" md="12">
					<table class="table table-sm">
						<thead>
							<tr>
								<th></th>
								<th>Kode</th>
								<th>Product</th>
								<th>Ukuran</th>
								<th class="text-right">Harga</th>
								<th class="text-right">Diskon</th>
								<th class="text-right">Jumlah</th>
								<th class="text-right">Total</th>
							</tr>
						</thead>
						<tbody>
							<tr :key="i" v-for="(p, i) in productSelected">
								<td>
									<button @click="deleteItem(i)" class="btn btn-sm btn-danger">
										DELETE
									</button>
								</td>
								<td>{{ p.kode }}</td>
								<td>{{ p.product.nama }}</td>
								<td>{{ size[p.size].nama + " / " + size[p.size].alias }}</td>
								<td class="text-right">{{ p.hargaJual.toLocaleString() }}</td>
								<td class="text-right">
									<input
										class="form-control form-control-sm"
										type="number"
										v-model.number="p.diskon"
										@change="diskon(i)"
									/>
								</td>
								<td class="text-right">
									<input
										class="form-control form-control-sm"
										type="number"
										v-model.number="p.jmlBeli"
										@change="diskon(i)"
									/>
								</td>
								<td class="text-right">{{ p.total.toLocaleString() }}</td>
							</tr>
							<tr>
								<th class="text-right" colspan="6">TOTAL</th>
								<th class="text-right">{{ total()["jml"] }}</th>
								<th class="text-right">
									{{ total()["uang"].toLocaleString() }}
								</th>
							</tr>
						</tbody>
					</table>
				</CCol>
			</CRow>
			<CRow>
				<div class="col-md-4 mb-2">
					<CButton @click="resetBarang" color="danger" block>
						KOSONGKAN KERANJANG
					</CButton>
				</div>
			</CRow>
		</CCardBody>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="xl"
			color="info"
			class="modal-xxl"
		>
			<qrcode-stream
				class=""
				:camera="camera"
				@decode="onDecode"
				:track="paintOutline"
				:torch="flash"
			></qrcode-stream>
			<template #header>
				<h6 class="modal-title">Set Harga Masal</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CLOSE</CButton>
				<CButton @click="flash = !flash" color="info">FLASH</CButton>
				<CButton @click="changeCam" color="info">CHANGE</CButton>
			</template>
		</CModal>
	</div>
</template>

<style lang="css">
.col-form-label-sm {
	padding-bottom: 0px;
}
label {
	margin-bottom: 0px;
}
.modal-xxl {
	width: 100%;
}
</style>

<script>
import { mapMutations, mapState } from "vuex";
import { size } from "@/plugins/utils";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
	components: {
		QrcodeStream,
	},
	data() {
		return {
			hide: false,
			products: [],
			productSelected: [],
			size,
			modal: false,
			masal: [],
			camera: "off",
			flash: false,
			filter: "",
		};
	},
	computed: {
		...mapState("order", ["product", "info"]),
	},
	watch: {
		productSelected: {
			deep: true,
			handler(val) {
				this.SET_PRODUCT(val);
			},
		},
		modal(val) {
			this.$refs.filter.$el.children[0].focus();
			this.camera = "off";
			if (val) {
				this.camera = "rear";
			}
		},
		masal: {
			deep: true,
			handler(val) {
				//this.SET_PRODUCT(val);
				for (let i = 0; i < val.length; i++) {
					const el = val[i];
					this.productSelected[i]["size"] = [];
					for (const k in size) {
						this.productSelected[i]["size"].push({
							ukuran: k,
							jml: el[k],
							hargaBeli: el.hargaBeli,
							hargaJual: el.hargaJual,
						});
					}

					// for (let ii = 0; ii < this.productSelected[i]["size"].length; ii++) {
					// 	//const el2 = this.productSelected[ii];
					// 	// this.productSelected[i].size
					// 	this.productSelected[i]["size"].push({
					// 		ukuran: 110,
					// 		jml: 0,
					// 		hargaBeli: 111,
					// 		hargaJual: 222,
					// 	});
					// }
				}
			},
		},
		filter(val) {
			this.onDecode(val);
		},
	},
	mounted() {
		this.flash = false;

		this.loadProducts();
		this.productSelected = this.product;
		for (let i = 0; i < this.product.length; i++) {
			let hargaBeli = 0;
			let hargaJual = 0;
			let z = [];

			for (let ii = 0; ii < this.product[i]["size"].length; ii++) {
				const el = this.product[i]["size"][ii];
				z[el.ukuran] = el.jml;
				el.hargaBeli > 0 ? (hargaBeli = el.hargaBeli) : false;
				el.hargaJual > 0 ? (hargaJual = el.hargaJual) : false;
			}
		}
	},
	methods: {
		...mapMutations("order", ["SET_PRODUCT"]),
		async loadProducts() {
			this.products = [];
			const { data } = await this.$http.get("product-item", {
				params: { filter: this.kode },
			});
			this.products = data.data.data;
			this.$refs.filter.$el.children[0].focus();
		},

		async onDecode(string) {
			let namaUkuran = "";
			let masuk = false;
			if (string.length >= 18) {
				const audio = new Audio(
					"https://soundbible.com/mp3/Chain%20Fence%20Jump-SoundBible.com-475442855.mp3"
				);
				audio.play();
				for (let i = 0; i < this.products.length; i++) {
					const el = this.products[i];
					if (el.kode.toLowerCase() == string.toLowerCase().trim()) {
						masuk = true;
						let sudahMasuk = false;
						let indexMasuk = 0;
						namaUkuran = el.product.nama + "(" + el.size + ")";

						for (let ii = 0; ii < this.productSelected.length; ii++) {
							const ell = this.productSelected[ii];
							if (ell.kode.toLowerCase() == string.toLowerCase()) {
								sudahMasuk = true;
								indexMasuk = ii;
								break;
							}
						}

						if (sudahMasuk) {
							const jmlBeli = this.productSelected[indexMasuk].jmlBeli + 1;
							const total =
								jmlBeli * this.productSelected[indexMasuk].hargaJual -
								jmlBeli * this.productSelected[indexMasuk].diskon;

							this.productSelected[indexMasuk].jmlBeli = jmlBeli;
							this.productSelected[indexMasuk].total = total;
						} else {
							this.productSelected.push({
								...el,
								jmlBeli: 1,
								diskon: 0,
								total: el.hargaJual,
							});
						}
					}
				}

				//console.log(this.productSelected);
				if (masuk) {
					const tmpCam = this.camera;
					this.camera = "off";
					this.$swal({
						icon: "success",
						title: namaUkuran,
						showConfirmButton: false,
						timer: 2500,
					});
					await this.timeout(500);
					this.camera = tmpCam;
					this.filter = "";
				}

				this.$refs.filter.$el.children[0].focus();
			}

			// ...
		},
		changeCam() {
			if (this.camera == "front") this.camera = "rear";
			else this.camera = "front";
		},

		onBarcodeScanned(barcode) {
			console.log(barcode);
			// do something...
		},

		paintOutline(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

				ctx.strokeStyle = "red";

				ctx.beginPath();
				ctx.moveTo(firstPoint.x, firstPoint.y);
				for (const { x, y } of otherPoints) {
					ctx.lineTo(x, y);
				}
				ctx.lineTo(firstPoint.x, firstPoint.y);
				ctx.closePath();
				ctx.stroke();
			}
		},

		paintCenterText(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const { boundingBox, rawValue } = detectedCode;

				const centerX = boundingBox.x + boundingBox.width / 2;
				const centerY = boundingBox.y + boundingBox.height / 2;

				// const fontSize = Math.max(
				// 	12,
				// 	(50 * boundingBox.width) / ctx.canvas.width
				// );
				// console.log(boundingBox.width, ctx.canvas.width);

				let fontSize = 10;

				ctx.font = `bold ${fontSize}px sans-serif`;
				ctx.textAlign = "center";

				ctx.lineWidth = 3;
				ctx.strokeStyle = "#35495e";
				ctx.strokeText(detectedCode.rawValue, centerX, centerY);

				ctx.fillStyle = "#5cb984";
				ctx.fillText(rawValue, centerX, centerY);
			}
		},
		timeout(ms) {
			return new Promise((resolve) => {
				window.setTimeout(resolve, ms);
			});
		},
		total() {
			let jml = 0;
			let uang = 0;
			for (let i = 0; i < this.productSelected.length; i++) {
				const el = this.productSelected[i];
				jml += el.jmlBeli;
				uang += el.total;
			}
			return { jml, uang };
		},
		resetBarang() {
			this.productSelected = [];
		},
		deleteItem(i) {
			this.productSelected.splice(i, 1);
		},
		diskon(i) {
			const jmlBeli = this.productSelected[i].jmlBeli;
			const diskon = this.productSelected[i].diskon; //parseInt(event.target.value);
			const total =
				jmlBeli * this.productSelected[i].hargaJual - jmlBeli * diskon;

			this.productSelected[i].diskon = diskon;
			this.productSelected[i].total = total;
		},
	},
};
</script>
